import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { PrivyProvider } from '@privy-io/react-auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ethers } from 'ethers';
import App from './App';
import { FANTOM_TESTNET } from './utils/chainConfig';

// Create global RPC provider
const RPC_URL = process.env.REACT_APP_RPC_URL || 'https://evm-rpc-testnet.sei-apis.com';
const rpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL);
window.rpcProvider = rpcProvider;

// Persist and rehydrate state
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('app_state', serializedState);
  } catch (err) {
    console.error('Error saving state:', err);
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('app_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error loading state:', err);
    return undefined;
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Privy error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong with Privy initialization.</h1>;
    }

    return this.props.children;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <PrivyProvider
          appId={process.env.REACT_APP_PRIVY_APP_ID || "cly2qlmyc05sfw8wiri8gtyx9"}
          config={{
            loginMethods: ['twitter'],
            appearance: {
              theme: '#11111b',
              accentColor: '#b4befe',
              logo: 'https://inari-app.vercel.app/privylogo.png',
              landingHeader: 'こんにちは',
            },
            embeddedWallets: {
              createOnLogin: 'users-without-wallets',
            },
            supportedChains: [FANTOM_TESTNET],
          }}
          onSuccess={(user) => {
            console.log('Privy authentication successful:', user);
            saveState({ user }); // Save user state
          }}
          onError={(error) => {
            console.error('Privy authentication error:', error);
          }}
        >
          <Router>
            <App initialState={loadState()} />
          </Router>
        </PrivyProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }, (err) => {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}

// Add update checking for the service worker
let refreshing = false;
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});