import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Typography, Avatar, Container, Box, CircularProgress, Alert, IconButton, Dialog, DialogContent, Button } from '@mui/material';
import { usePrivyAuth } from '../firebase/auth';
import { doc, getDoc, query, where, collection, getDocs, onSnapshot, setDoc, arrayUnion, arrayRemove, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IosShare } from '@mui/icons-material';
import QuoteTweet from './QuoteTweet';

const API_URL = process.env.REACT_APP_SERVER_URL;

const FrostedGlassBox = styled(Box, {
  shouldForwardProp: (prop) => !['intensity', 'isDarkMode', 'isTransparent', 'outlineColor', 'isContainer'].includes(prop)
})(({ theme, intensity, isDarkMode, isTransparent, outlineColor, isContainer }) => {
  const getBackgroundColor = () => {
    if (isDarkMode) {
      return intensity === 'low' ? 'rgba(49, 50, 68, 0.8)' :
             intensity === 'medium' ? 'rgba(30, 30, 46, 0.9)' :
             'rgba(30, 30, 30, 1)';
    } else {
      return intensity === 'low' ? 'rgba(255, 255, 255, 0.8)' :
             intensity === 'medium' ? 'rgba(255, 255, 255, 0.9)' :
             'rgba(255, 255, 255, 1)';
    }
  };

  const getBorderColor = () => outlineColor || (isDarkMode ? 'rgba(180, 190, 254, 0.2)' : 'rgba(0, 0, 0, 0.2)');

  return {
    background: isTransparent ? 'transparent' : getBackgroundColor(),
    backdropFilter: isTransparent ? 'none' : 'blur(10px)',
    borderRadius: isContainer ? '0' : '12px',
    border: isContainer ? 'none' : `1px solid ${getBorderColor()}`,
    boxShadow: isContainer || isTransparent ? 'none' : `0 4px 30px ${isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
    transition: 'all 0.3s ease-in-out',
    padding: isContainer ? '0' : '12px',
    marginBottom: isContainer ? '0' : '16px',
    '&:hover': {
      boxShadow: isContainer || isTransparent ? 'none' : `0 6px 40px ${isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(180, 190, 254, 0.2)'}`,
    },
  };
});

const formatDate = (date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  return `${seconds}s`;
};

const Post = React.memo(({ post, commentCount, quoteCount, handleLike, handleQuoteButtonClick, currentUserId, likes }) => {
  const navigate = useNavigate();
  const likeData = likes[post.id] || { count: 0, users: [] };
  const isLiked = likeData.users.includes(currentUserId);

  const handleCommentClick = (e) => {
    e.preventDefault();
    navigate(`/post/${post.id}`);
  };

  return (
    <FrostedGlassBox isDarkMode intensity="medium" sx={{ mb: 2, p: 2, borderRadius: 2 }}>
      <Link to={`/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Avatar src={post.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 48, height: 48, mr: 2 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1, color: '#f9e2af' }}>
                @{post.userName || 'Anonymous'}
              </Typography>
              <Typography variant="caption" sx={{ color: '#7f849c' }}>
                · {post.createdAt ? formatDate(new Date(post.createdAt.seconds * 1000)) : 'Date unavailable'}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: '#cdd6f4', whiteSpace: 'pre-wrap' }}>
              {post.content || post.text}
            </Typography>
            {post.imageUrl && (
              <Box 
                component="img"
                src={post.imageUrl}
                sx={{ width: '100%', borderRadius: 2, mb: 2 }}
                alt="Post content"
                loading="lazy"
              />
            )}
            {post.isQuoteTweet && post.originalPostText && (
              <FrostedGlassBox isDarkMode intensity="low" sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={post.originalUserPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
                  <Typography variant="subtitle2" sx={{ color: '#cdd6f4' }}>
                    @{post.originalUserName || post.originalPostAuthor || 'Anonymous'}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#cdd6f4' }}>
                  {post.originalPostText}
                </Typography>
              </FrostedGlassBox>
            )}
          </Box>
        </Box>
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <IconButton size="small" onClick={handleCommentClick}>
          <ChatBubbleOutlineIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {commentCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={() => handleQuoteButtonClick(post)}>
          <RepeatIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {quoteCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={(e) => { e.preventDefault(); handleLike(post.id); }}>
          {isLiked ? (
            <FavoriteIcon fontSize="small" sx={{ color: '#f38ba8' }} />
          ) : (
            <FavoriteBorderIcon fontSize="small" sx={{ color: '#7f849c' }} />
          )}
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {likeData.count}
          </Typography>
        </IconButton>
        <IconButton size="small">
          <IosShare fontSize="small" sx={{ color: '#7f849c' }} />
        </IconButton>
      </Box>
    </FrostedGlassBox>
  );
});

const Profile = ({ userId: propUserId }) => {
  const { user } = usePrivyAuth();
  const { userId, twitterHandle } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentCounts, setCommentCounts] = useState({});
  const [quoteCounts, setQuoteCounts] = useState({});
  const [likes, setLikes] = useState({});
  const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);
  const [postToQuote, setPostToQuote] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        let userDoc;
        if (propUserId || userId) {
          userDoc = await getDoc(doc(firestore, 'users', propUserId || userId || user.privyUserId));
        } else if (twitterHandle) {
          const q = query(collection(firestore, 'users'), where('twitterHandle', '==', twitterHandle));
          let querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            const allUsersSnapshot = await getDocs(collection(firestore, 'users'));
            const matchingUser = allUsersSnapshot.docs.find(
              doc => doc.data().twitterHandle.toLowerCase() === twitterHandle.toLowerCase()
            );
            if (matchingUser) {
              userDoc = matchingUser;
            }
          } else {
            userDoc = querySnapshot.docs[0];
          }
        }

        if (userDoc && userDoc.exists()) {
          const userData = userDoc.data();
          setProfileData(userData);
          setIsFollowing(userData.followers?.includes(user.id) || false);
          setFollowersCount(userData.followers?.length || 0);
          setFollowingCount(userData.following?.length || 0);
          await fetchUserPosts(userData);
        } else {
          console.log('No such document!');
          setError('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [propUserId, userId, twitterHandle, user]);

  const fetchUserPosts = async (userData) => {
    try {
      const postsQuery = query(
        collection(firestore, 'posts'),
        where('postOwner', '==', userData.ethereumAddress)
      );
      const postsSnapshot = await getDocs(postsQuery);
      
      const userPosts = postsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sort posts by createdAt in descending order
      userPosts.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

      setPosts(userPosts);

      // Fetch comment counts and quote counts in parallel
      const [commentCountsData, quoteCountsData] = await Promise.all([
        Promise.all(userPosts.map(async (post) => {
          const commentsRef = collection(firestore, `posts/${post.id}/comments`);
          const snapshot = await getDocs(commentsRef);
          return { [post.id]: snapshot.size };
        })),
        Promise.all(userPosts.map(async (post) => {
          const quotesRef = query(collection(firestore, 'posts'), where('originalPostId', '==', post.id));
          const snapshot = await getDocs(quotesRef);
          return { [post.id]: snapshot.size };
        }))
      ]);

      setCommentCounts(Object.assign({}, ...commentCountsData));
      setQuoteCounts(Object.assign({}, ...quoteCountsData));

      // Set up listeners for likes
      const unsubscribes = userPosts.map(post => 
        onSnapshot(
          collection(firestore, `posts/${post.id}/likes`),
          (snapshot) => {
            const likesData = {};
            snapshot.docs.forEach(doc => {
              likesData[doc.id] = doc.data();
            });
            setLikes(prevLikes => ({...prevLikes, [post.id]: likesData[post.id] || { count: 0, users: [] }}));
          },
          (err) => {
            console.error('Error fetching likes:', err);
          }
        )
      );

      return () => unsubscribes.forEach(unsub => unsub());
    } catch (error) {
      console.error('Error fetching user posts:', error);
      setError('Failed to fetch user posts');
    }
  };

  const handleLike = useCallback(async (postId) => {
    if (!user) return;
    
    const likeRef = doc(firestore, `posts/${postId}/likes`, postId);
    const currentLikes = likes[postId] || { count: 0, users: [] };
    const isLiked = currentLikes.users.includes(user.id);
  
    try {
      if (isLiked) {
        await setDoc(likeRef, {
          count: currentLikes.count - 1,
          users: arrayRemove(user.id)
        }, { merge: true });
      } else {
        await setDoc(likeRef, {
          count: currentLikes.count + 1,
          users: arrayUnion(user.id)
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating like:', error);
      setError('Failed to update like');
    }
  }, [user, likes]);

  const handleQuoteButtonClick = useCallback((post) => {
    setPostToQuote(post);
    setQuoteDialogOpen(true);
  }, []);

  const handleQuoteClose = useCallback(() => {
    setQuoteDialogOpen(false);
    setPostToQuote(null);
  }, []);

  const handleQuoteSuccess = useCallback(() => {
    handleQuoteClose();
    // Refresh the posts after a successful quote
    if (profileData) {
      fetchUserPosts(profileData);
    }
  }, [handleQuoteClose, profileData]);

  const handleFollowToggle = async () => {
    if (!user || !profileData) return;
  
    const currentUserRef = doc(firestore, 'users', user.id);
    const profileUserRef = doc(firestore, 'users', profileData.privyUserId);
  
    try {
      if (isFollowing) {
        await updateDoc(currentUserRef, {
          following: arrayRemove(profileData.privyUserId)
        });
        await updateDoc(profileUserRef, {
          followers: arrayRemove(user.id)
        });
        setFollowersCount(prev => prev - 1);
      } else {
        await updateDoc(currentUserRef, {
          following: arrayUnion(profileData.privyUserId)
        });
        await updateDoc(profileUserRef, {
          followers: arrayUnion(user.id)
        });
        setFollowersCount(prev => prev + 1);
  
        // Create a notification
        await fetch(`${API_URL.replace(/\/$/, '')}/api/follow`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            followerPrivyUserId: user.id,
            followeePrivyUserId: profileData.privyUserId,
            followerUsername: user.twitter?.username || 'Anonymous'
          }),
        });
      }
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error('Error toggling follow:', error);
      setError(`Failed to ${isFollowing ? 'unfollow' : 'follow'} user`);
    }
  };

  const memoizedPosts = useMemo(() => posts, [posts]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        <Typography>{error}</Typography>
      </Alert>
    );
  }

  if (!profileData) {
    return <Typography>User not found</Typography>;
  }

  return (
    <Container maxWidth="sm">
      <FrostedGlassBox isDarkMode intensity="medium" sx={{ mb: 4, p: 3, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar 
            src={profileData.photoURL || 'https://via.placeholder.com/150'} 
            alt={profileData.twitterHandle} 
            sx={{ width: 100, height: 100, mb: 2 }} 
          />
          <Typography variant="h5" sx={{ color: '#f9e2af' }}>@{profileData.twitterHandle}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <Typography variant="body2" sx={{ mr: 2, color: '#cdd6f4' }}>
              Followers: {followersCount}
            </Typography>
            <Typography variant="body2" sx={{ color: '#cdd6f4' }}>
              Following: {followingCount}
            </Typography>
          </Box>
          {user && user.id !== profileData.privyUserId && (
            <Button
              variant="contained"
              color={isFollowing ? "secondary" : "primary"}
              onClick={handleFollowToggle}
              sx={{ mt: 2 }}
            >
              {isFollowing ? "Unfollow" : "Follow"}
            </Button>
          )}
        </Box>
      </FrostedGlassBox>
      <Box>
        <Typography variant="h6" sx={{ mb: 2, color: '#cdd6f4' }}>Posts</Typography>
        {memoizedPosts.map(post => (
          <Post 
            key={post.id}
            post={post}
            handleQuoteButtonClick={handleQuoteButtonClick}
            commentCount={commentCounts[post.id] || 0}
            quoteCount={quoteCounts[post.id] || 0}
            handleLike={handleLike}
            currentUserId={user?.id}
            likes={likes}
          />
        ))}
      </Box>
      <Dialog open={quoteDialogOpen} onClose={handleQuoteClose} fullWidth maxWidth="sm">
        <DialogContent>
          {postToQuote && (
            <QuoteTweet
              postToQuote={postToQuote}
              onClose={handleQuoteClose}
              onSuccess={handleQuoteSuccess}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default React.memo(Profile);