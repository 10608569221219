import React from 'react';
import { Box, IconButton, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WalletInfo from './WalletInfo';
import FrostedGlass from './FrostedGlass';

const CustomAppBar = ({ user }) => {
  const navigate = useNavigate();

  return (
    <FrostedGlass
      intensity="medium"
      darkMode={true}
      isContainer={true}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        display: 'flex',
        flexDirection: 'column',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(24, 24, 37, 0.8)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 'calc(env(safe-area-inset-top) + 56px)',
          px: 2,
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <IconButton onClick={() => navigate('/profile')}>
          <Avatar
            src={user?.twitter?.profilePictureUrl || 'https://via.placeholder.com/40'}
            alt={user?.twitter?.username || 'User'}
            sx={{
              width: 40,
              height: 40,
              border: '2px solid #b4befe',
              boxShadow: '0 0 10px rgba(180, 190, 254, 0.5)',
            }}
          />
        </IconButton>
        <WalletInfo />
      </Box>
    </FrostedGlass>
  );
};

export default CustomAppBar;