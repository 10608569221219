import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Typography, 
  Avatar, 
  Box, 
  CircularProgress, 
  IconButton, 
  TextField, 
  Button, 
  Slider, 
  AppBar, 
  Toolbar, 
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Input,
  Chip,
  Snackbar
} from '@mui/material';
import { doc, getDoc, collection, query, orderBy, onSnapshot, setDoc, serverTimestamp, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { firestore, storage } from '../firebase/firebase';
import { ChatBubbleOutline, Repeat, FavoriteBorder, Favorite, IosShare, ArrowBack, Close as CloseIcon, PhotoCamera, RocketLaunch } from '@mui/icons-material';
import { usePrivy } from '@privy-io/react-auth';
import { useWallets } from '@privy-io/react-auth';
import { useEthereumManager } from '../utils/eth';
import { ethers } from 'ethers';
import { encodeFunctionData, decodeEventLog } from 'viem';
import BANGER_ABI from '../contract/BangerABI.json';
import Comment from './Comment';
import QuoteTweet from './QuoteTweet';
import FrostedGlass from './FrostedGlass';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const BANGER_ADDRESS = process.env.REACT_APP_BANGER_CONTRACT_ADDRESS;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '0x530';

const PostView = ({ setShowBottomNav }) => {
  const { postId, commentId } = useParams();
  const navigate = useNavigate();
  const { ready, authenticated, login, user } = usePrivy();
  const { wallets } = useWallets();
  const { getHighestPaidCommentAmount, getDebugPriceAndFee, getBoostedCommentAmounts } = useEthereumManager();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [submittingComment, setSubmittingComment] = useState(false);
  const [boostAmount, setBoostAmount] = useState(0);
  const [boostAmountDisplay, setBoostAmountDisplay] = useState('');
  const [highestPaidAmount, setHighestPaidAmount] = useState('0');
  const [commentThread, setCommentThread] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [likes, setLikes] = useState({});
  const [boostedCommentAmounts, setBoostedCommentAmounts] = useState([]);
  const hasLoadedCommentData = useRef(false);
  const [showQuoteTweet, setShowQuoteTweet] = useState(false);
  const [itemToQuote, setItemToQuote] = useState(null);
  const [showFullScreenComment, setShowFullScreenComment] = useState(false);
  const textFieldRef = useRef(null);
  const fileInputRef = useRef(null);
  const [showBoostDialog, setShowBoostDialog] = useState(false);
  const [boostInputValue, setBoostInputValue] = useState('');
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const sortedComments = useMemo(() => {
    return comments.sort((a, b) => {
      if (a.isBoosted && !b.isBoosted) return -1;
      if (!a.isBoosted && b.isBoosted) return 1;
      if (a.isBoosted && b.isBoosted) {
        const amountA = ethers.BigNumber.from(a.amountPaid || '0');
        const amountB = ethers.BigNumber.from(b.amountPaid || '0');
        return amountB.gt(amountA) ? 1 : amountB.lt(amountA) ? -1 : 0;
      }
      const aTime = a.createdAt?.seconds || Date.now() / 1000;
      const bTime = b.createdAt?.seconds || Date.now() / 1000;
      return bTime - aTime;
    });
  }, [comments]);

  useEffect(() => {
    if (!ready) {
      console.log('Privy is initializing...');
    } else if (!authenticated) {
      console.log('User is not authenticated, redirecting to login...');
      login();
    }
  }, [ready, authenticated, login]);

  useEffect(() => {
    const handleResize = () => {
      const newKeyboardHeight = window.innerHeight - window.visualViewport.height;
      setKeyboardHeight(newKeyboardHeight);
    };

    window.visualViewport.addEventListener('resize', handleResize);
    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, []);

  const fetchPost = useCallback(async () => {
    try {
      const postDoc = await getDoc(doc(firestore, 'posts', postId));
      if (postDoc.exists()) {
        setPost({ id: postDoc.id, ...postDoc.data() });
      } else {
        setError('Post not found');
      }
    } catch (err) {
      console.error('Error fetching post:', err);
      setError('Error fetching post');
    }
  }, [postId]);

  useEffect(() => {
    if (authenticated && postId) {
      fetchPost();
      
      const unsubscribe = onSnapshot(
        query(
          collection(firestore, `posts/${postId}/comments`),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          const commentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setComments(commentsData);
          setLoading(false);
          
          if (commentId) {
            const thread = getCommentThread(commentsData, commentId);
            setCommentThread(thread);
          } else {
            setCommentThread([]);
          }
        },
        (err) => {
          console.error('Error fetching comments:', err);
          setError('Error fetching comments');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }
  }, [authenticated, postId, commentId, fetchPost]);

  useEffect(() => {
    if (authenticated && postId) {
      const unsubscribe = onSnapshot(
        collection(firestore, `posts/${postId}/likes`),
        (snapshot) => {
          const likesData = {};
          snapshot.docs.forEach(doc => {
            likesData[doc.id] = doc.data();
          });
          setLikes(likesData);
        },
        (err) => {
          console.error('Error fetching likes:', err);
        }
      );

      return () => unsubscribe();
    }
  }, [authenticated, postId]);

  const getCommentThread = (comments, targetCommentId) => {
    const thread = [];
    let currentCommentId = targetCommentId;

    while (currentCommentId) {
      const comment = comments.find(c => c.uniqueCommentId === currentCommentId);
      if (comment) {
        thread.unshift(comment);
        currentCommentId = comment.parentCommentId;
      } else {
        break;
      }
    }

    return thread;
  };

  useEffect(() => {
    const fetchHighestPaidAmount = async () => {
      if (post?.uniqueId && !hasLoadedCommentData.current) {
        try {
          const amount = await getHighestPaidCommentAmount(post.uniqueId);
          console.log('Highest paid amount:', amount);
          setHighestPaidAmount(amount === "0.0" || !amount ? "0" : amount);

          const boostedAmounts = await getBoostedCommentAmounts(post.uniqueId);
          console.log('Boosted comment amounts:', boostedAmounts);
          setBoostedCommentAmounts(boostedAmounts);

          hasLoadedCommentData.current = true;
        } catch (error) {
          console.error('Error fetching highest paid comment amount:', error);
          setHighestPaidAmount('0');
        }
      }
    };

    fetchHighestPaidAmount();
  }, [post?.uniqueId, getHighestPaidCommentAmount, getBoostedCommentAmounts]);

  useEffect(() => {
    hasLoadedCommentData.current = false;
  }, [postId]);

  const formatDate = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  const getReplyCount = (itemId) => {
    return comments.filter(c => c.parentCommentId === itemId).length;
  };

  const handleLike = async (itemId) => {
    if (!authenticated || !user) return;
    
    const likeRef = doc(firestore, `posts/${postId}/likes`, itemId);
    const currentLikes = likes[itemId] || { count: 0, users: [] };
    const isLiked = currentLikes.users.includes(user.id);

    try {
      if (isLiked) {
        await updateDoc(likeRef, {
          count: currentLikes.count - 1,
          users: arrayRemove(user.id)
        });
      } else {
        await setDoc(likeRef, {
          count: currentLikes.count + 1,
          users: arrayUnion(user.id)
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  const handleQuoteButtonClick = useCallback((item) => {
    console.log('Opening quote dialog for item:', item);
    const uniqueId = item.uniqueCommentId || item.uniqueId;
    setItemToQuote({...item, uniqueId});
    setShowQuoteTweet(true);
  }, []);

  const handleQuoteClose = useCallback(() => {
    setShowQuoteTweet(false);
    setItemToQuote(null);
  }, []);

  const handleQuoteSuccess = useCallback(() => {
    console.log('Quote tweet successful, refreshing comments');
    handleQuoteClose();
    fetchPost();
  }, [handleQuoteClose, fetchPost]);

  const handleShare = async (item) => {
    const isPost = !item.uniqueCommentId;
    const itemUrl = `${window.location.origin}/post/${postId}${isPost ? '' : `/comment/${item.uniqueCommentId}`}`;
    
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${isPost ? 'Post' : 'Comment'} by @${item.userName}`,
          text: item.content.substring(0, 50) + '...',
          url: itemUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback to copying the link
      navigator.clipboard.writeText(itemUrl).then(() => {
        setSnackbarMessage('Link copied to clipboard!');
        setShowSnackbar(true);
      }, (err) => {
        console.error('Could not copy text: ', err);
        setSnackbarMessage('Failed to copy link');
        setShowSnackbar(true);
      });
    }
  };

  const renderPostOrComment = (item, isMainPost = false, index = -1) => {
    const itemId = item.uniqueCommentId || item.id;
    const likeData = likes[itemId] || { count: 0, users: [] };
    const isLiked = likeData.users.includes(user?.id);
    const isBoosted = isMainPost ? item.isBoosted : item.isBoosted;
    const boostedAmount = isBoosted ? ethers.utils.formatEther(item.amountPaid || '0') : '0';
  
    return (
      <FrostedGlass 
        darkMode 
        intensity="medium" 
        sx={{ 
          mb: 3, 
          cursor: 'pointer',
          borderRadius: '8px',
          position: 'relative',
          '&::before': isBoosted ? {
            content: '""',
            position: 'absolute',
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            borderRadius: '10px',
            background: 'linear-gradient(45deg, #f5e0dc, #f2cdcd, #f5c2e7, #cba6f7, #f38ba8, #eba0ac, #fab387, #f9e2af, #a6e3a1, #94e2d5, #89dceb, #74c7ec, #89b4fa, #b4befe)',
            backgroundSize: '1400% 1400%',
            animation: 'rainbow 10s ease infinite',
            zIndex: -1,
          } : {},
          '&::after': isBoosted ? {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '8px',
            background: 'inherit',
            zIndex: -1,
          } : {},
          '@keyframes rainbow': {
            '0%': { backgroundPosition: '0% 50%' },
            '50%': { backgroundPosition: '100% 50%' },
            '100%': { backgroundPosition: '0% 50%' },
          },
        }}
        onClick={() => handleCommentClick(item, index)}
      >
<Box sx={{ display: 'flex', mb: 2 }}>
          <Link 
            to={`/${item.userName}`} 
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Avatar src={item.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 48, height: 48, mr: 2 }} />
          </Link>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1, color: '#f9e2af' }}>
                @{item.userName || 'Anonymous'}
              </Typography>
              <Typography variant="caption" sx={{ color: '#7f849c' }}>
                · {item.createdAt ? formatDate(new Date(item.createdAt.seconds * 1000)) : 'Date unavailable'}
              </Typography>
              {isBoosted && (
                <Typography variant="caption" sx={{ color: '#fab387', ml: 1 }}>
                  {boostedAmount} SEI
                </Typography>
              )}
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: '#cdd6f4', whiteSpace: 'pre-wrap' }}>
              {item.content || item.text}
            </Typography>
            {item.imageUrl && (
              <Box 
                component="img"
                src={item.imageUrl}
                sx={{ width: '100%', borderRadius: 2, mb: 2 }}
                alt="Post content"
                loading="lazy"
              />
            )}
            {item.isQuoteTweet && (item.originalPostText || item.originalPostImageUrl) && (
              <Box sx={{ 
                p: 2, 
                borderRadius: 2, 
                mt: 2, 
                mb: 2, 
                background: 'rgba(49, 50, 68, 0.8)',
                border: '1px solid rgba(180, 190, 254, 0.2)'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={item.originalUserPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
                  <Typography variant="subtitle2" sx={{ color: '#cdd6f4' }}>
                    @{item.originalUserName || item.originalPostAuthor || 'Anonymous'}
                  </Typography>
                </Box>
                {item.originalPostText && (
                  <Typography variant="body2" sx={{ color: '#cdd6f4', mb: 1 }}>
                    {item.originalPostText}
                  </Typography>
                )}
                {item.originalPostImageUrl && (
                  <Box 
                    component="img"
                    src={item.originalPostImageUrl}
                    sx={{ width: '100%', borderRadius: 2 }}
                    alt="Original post content"
                    loading="lazy"
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleOpenFullScreenComment(item); }}>
            <ChatBubbleOutline fontSize="small" sx={{ color: '#7f849c' }} />
            <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
              {isMainPost ? comments.length : getReplyCount(item.uniqueCommentId || item.id)}
            </Typography>
          </IconButton>
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleQuoteButtonClick(item); }}>
            <Repeat fontSize="small" sx={{ color: '#7f849c' }} />
          </IconButton>
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleLike(itemId); }}>
            {isLiked ? (
              <Favorite fontSize="small" sx={{ color: '#f38ba8' }} />
            ) : (
              <FavoriteBorder fontSize="small" sx={{ color: '#7f849c' }} />
            )}
            <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
              {likeData.count}
            </Typography>
          </IconButton>
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleShare(item); }}>
            <IosShare fontSize="small" sx={{ color: '#7f849c' }} />
          </IconButton>
        </Box>
      </FrostedGlass>
    );
  };

  const handleBack = () => {
    if (showFullScreenComment) {
      handleCloseFullScreenComment();
    } else if (commentThread.length > 1) {
      const parentComment = commentThread[commentThread.length - 2];
      navigate(`/post/${postId}/comment/${parentComment.uniqueCommentId}`, { replace: true });
    } else if (commentThread.length === 1) {
      navigate(`/post/${postId}`, { replace: true });
    } else {
      navigate(-1);
    }
  };

  const topLevelComments = useMemo(() => {
    return sortedComments.filter(comment => !comment.parentCommentId);
  }, [sortedComments]);

  const replies = useMemo(() => {
    if (commentThread.length > 0) {
      const lastComment = commentThread[commentThread.length - 1];
      return sortedComments.filter(comment => comment.parentCommentId === lastComment.uniqueCommentId);
    }
    return [];
  }, [sortedComments, commentThread]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!newComment.trim() && !image) {
      setError('Please enter some text or upload an image.');
      return;
    }
    setSubmittingComment(true);

    try {
      if (!authenticated) {
        await login();
      }

      const parentId = selectedComment ? selectedComment.uniqueCommentId : null;
      const result = await createCommentOnBlockchain(post.uniqueId, newComment, parentId);
      console.log('Comment result:', result);

      if (!result.uniqueCommentId) {
        throw new Error('Failed to get uniqueCommentId from blockchain transaction');
      }

      let imageUrl = null;
      if (image) {
        const imageRef = ref(storage, `comments/${user.id}/${Date.now()}_${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await addCommentToFirestore(result, newComment, parentId, imageUrl);
      await createNotification(result.uniqueCommentId, post.userId);

      setNewComment('');
      setBoostAmount(0);
      setBoostAmountDisplay('');
      setShowFullScreenComment(false);
      setSelectedComment(null);
      setImage(null);
      setPreview(null);
      setShowBottomNav(true);
    } catch (error) {
      console.error('Error adding comment:', error);
      setError(`Failed to add comment: ${error.message}`);
    } finally {
      setSubmittingComment(false);
    }
  };

  const createCommentOnBlockchain = async (uniquePostId, content, parentCommentId) => {
    if (wallets.length === 0) throw new Error("No wallets available");
  
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
  
    try {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const feeInWei = boostAmount > 0 ? 
        await contract.calculateFeeInWei(boostAmount) : 
        await contract.calculateFeeInWei(1);  // 1 USD fee for regular comments
  
      console.log('Fee in Wei:', ethers.utils.formatEther(feeInWei), 'SEI');
  
      const debugInfo = await getDebugPriceAndFee();
      console.log('Debug Info:', debugInfo);
  
      let formattedParentCommentId = parentCommentId || ethers.constants.HashZero;
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: boostAmount > 0 ? 'addBoostedComment' : 'addComment',
        args: boostAmount > 0 ? [uniquePostId, content] : [uniquePostId, content, formattedParentCommentId]
      });
  
      // EIP-1559 gas parameters
      const maxPriorityFeePerGas = ethers.utils.parseUnits('100', 'gwei'); // Set priority fee to 100 gwei
      const maxFeePerGas = maxPriorityFeePerGas.mul(2); // Set max fee to double the priority fee
  
      const estimatedGasLimit = await contract.estimateGas[boostAmount > 0 ? 'addBoostedComment' : 'addComment'](
        ...(boostAmount > 0 ? [uniquePostId, content] : [uniquePostId, content, formattedParentCommentId]),
        { value: feeInWei }
      );
      const gasLimit = estimatedGasLimit.mul(120).div(100); // Add 20% buffer to estimated gas limit
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        chainId: CHAIN_ID,
        from: wallet.address,
        maxPriorityFeePerGas: ethers.utils.hexValue(maxPriorityFeePerGas),
        maxFeePerGas: ethers.utils.hexValue(maxFeePerGas),
        gasLimit: ethers.utils.hexValue(gasLimit),
        type: 2 // Explicitly set transaction type to EIP-1559
      };
  
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'SEI');
  
      if (ethers.BigNumber.from(balance).lt(feeInWei.add(maxFeePerGas.mul(gasLimit)))) {
        throw new Error("Insufficient balance to pay the fee and gas");
      }
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log(`Transaction sent. Hash: ${txHash}`);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log(`Transaction mined. Block number: ${receipt.blockNumber}`);
  
      const commentAddedEvent = receipt.logs.find(log => {
        try {
          const decoded = decodeEventLog({
            abi: BANGER_ABI,
            data: log.data,
            topics: log.topics,
          });
          return decoded.eventName === 'CommentAdded';
        } catch (e) {
          return false;
        }
      });
  
      if (!commentAddedEvent) {
        console.error('All logs:', receipt.logs);
        throw new Error("CommentAdded event not found in transaction logs");
      }
  
      const decodedEvent = decodeEventLog({
        abi: BANGER_ABI,
        data: commentAddedEvent.data,
        topics: commentAddedEvent.topics,
      });
  
      console.log('Decoded CommentAdded event:', decodedEvent);
  
      if (!decodedEvent.args) {
        throw new Error('Decoded event args not found');
      }
  
      const uniqueCommentId = decodedEvent.args.uniqueCommentId ?? null;
      const commentId = decodedEvent.args.commentId?.toString() ?? null;
      const amountPaid = decodedEvent.args.amountPaid?.toString() ?? '0';
      const eventIsBoosted = decodedEvent.args.isBoosted ?? false;
  
      return { txHash, commentId, uniqueCommentId, amountPaid, isBoosted: eventIsBoosted };
    } catch (error) {
      console.error("Error creating comment on blockchain:", error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        throw new Error("Insufficient funds to create comment");
      } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        throw new Error("Unable to estimate gas. The transaction may fail or the contract conditions are not met.");
      } else {
        throw error;
      }
    }
  };

  const waitForTransaction = (provider, txHash) => {
    return new Promise((resolve, reject) => {
      const checkReceipt = async () => {
        try {
          const receipt = await provider.request({
            method: 'eth_getTransactionReceipt',
            params: [txHash],
          });
          if (receipt) {
            resolve(receipt);
          } else {
            setTimeout(checkReceipt, 1000);
          }
        } catch (error) {
          reject(error);
        }
      };
      checkReceipt();
    });
  };

  const addCommentToFirestore = async (result, content, parentCommentId, imageUrl) => {
    const commentData = {
      content,
      userId: user.id,
      userName: user.twitter?.username || user.email || 'Anonymous',
      userPhoto: user.twitter?.profilePictureUrl || user.profilePictureUrl || 'default-photo-url',
      createdAt: serverTimestamp(),
      parentCommentId: parentCommentId || null,
      uniqueCommentId: result.uniqueCommentId,
      commentId: result.commentId,
      transactionHash: result.txHash,
      isBoosted: result.isBoosted,
      amountPaid: result.amountPaid,
      imageUrl: imageUrl || null
    };
      
    await setDoc(doc(firestore, `posts/${postId}/comments`, result.uniqueCommentId), commentData);
  };

  const createNotification = async (commentId, recipientId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL.replace(/\/$/, '')}/api/notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'comment',
          actorId: user.id,
          recipientId: recipientId,
          postId: postId,
          commentId: commentId,
          content: `${user.twitter?.username || 'Someone'} commented on your post`
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create notification');
      }
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const handleCommentClick = (comment, index) => {
    if (index === -1) {
      navigate(`/post/${postId}/comment/${comment.uniqueCommentId || comment.id}`, { replace: true });
    } else {
      navigate(`/post/${postId}/comment/${commentThread[index].uniqueCommentId}`, { replace: true });
    }
  };

  const handleOpenFullScreenComment = (comment = null) => {
    setSelectedComment(comment || post);
    setShowFullScreenComment(true);
    setShowBottomNav(false);
    
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 100);
  };

  const handleCloseFullScreenComment = () => {
    setShowFullScreenComment(false);
    setNewComment('');
    setBoostAmount(0);
    setBoostAmountDisplay('');
    setSelectedComment(null);
    setImage(null);
    setPreview(null);
    setShowBottomNav(true);
  };

  const handleBoostClick = () => {
    setShowBoostDialog(true);
  };

  const handleBoostDialogClose = () => {
    setShowBoostDialog(false);
    setBoostInputValue('');
  };

  const handleBoostConfirm = () => {
    const boostAmount = parseFloat(boostInputValue);
    if (!isNaN(boostAmount) && boostAmount > 0) {
      setBoostAmount(boostAmount);
      setBoostAmountDisplay(`$${boostAmount.toFixed(2)}`);
    } else {
      setBoostAmount(0);
      setBoostAmountDisplay('');
    }
    handleBoostDialogClose();
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedImage = await processImage(file);
        setImage(processedImage);
        setPreview(URL.createObjectURL(processedImage));
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const processImage = async (file) => {
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('File size exceeds 5MB limit. Please choose a smaller file.');
    }

    if (!file.type.startsWith('image/')) {
      throw new Error('Please upload an image file.');
    }

    if (file.type === 'image/gif') {
      return file;
    }

    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        let { width, height } = img;
        
        if (width > 4096 || height > 4096) {
          if (width > height) {
            height *= 4096 / width;
            width = 4096;
          } else {
            width *= 4096 / height;
            height = 4096;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(resolve, file.type, 0.95);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const renderReplyingTo = (item) => {
    return (
      <Box sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Avatar src={item.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
          <Box>
            <Typography variant="body2" sx={{ color: '#cdd6f4', fontWeight: 'bold' }}>
              @{item.userName || 'Anonymous'}
            </Typography>
            <Typography variant="body2" sx={{ color: '#cdd6f4', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
              {item.content || item.text}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderFullScreenComment = () => (
    <Box sx={{ 
      height: '100vh', 
      width: '100vw',
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: 'background.default',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2,
        pt: 'calc(env(safe-area-inset-top) + 8px)',
        pb: 1,
        borderBottom: 1,
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}>
        <IconButton onClick={handleCloseFullScreenComment} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Reply</Typography>
        <Button 
          onClick={handleSubmit}
          disabled={submittingComment || (!newComment.trim() && !image)}
          sx={{ color: 'primary.main' }}
        >
          {submittingComment ? <CircularProgress size={24} /> : 'Post'}
        </Button>
      </Box>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        overflow: 'auto',
        paddingBottom: '60px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <Avatar src={user?.twitter?.profilePictureUrl || 'https://via.placeholder.com/150'} sx={{ width: 40, height: 40, mr: 2 }} />
          <TextField
            inputRef={textFieldRef}
            placeholder="Write your reply..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            fullWidth
            multiline
            rows={4}
            variant="standard"
            InputProps={{ 
              disableUnderline: true,
              style: { color: '#cdd6f4' }
            }}
            autoFocus
          />
        </Box>
        {boostAmountDisplay && (
          <Chip
            label={`Boost: ${boostAmountDisplay}`}
            color="primary"
            size="small"
            sx={{ mt: 1, mb: 2 }}
            onDelete={() => {
              setBoostAmount(0);
              setBoostAmountDisplay('');
            }}
          />
        )}
        {preview && (
          <Box mb={2}>
            <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px' }} />
          </Box>
        )}
        {selectedComment && renderReplyingTo(selectedComment)}
      </Box>
      <Box sx={{ 
        position: 'fixed', 
        bottom: `calc(${keyboardHeight}px + env(safe-area-inset-bottom))`, 
        left: 0, 
        right: 0, 
        borderTop: 1, 
        borderColor: 'divider', 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        zIndex: 1000,
        transition: 'bottom 0.3s'
      }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <IconButton color="primary" onClick={handleImageClick}>
          <PhotoCamera />
        </IconButton>
        <IconButton color="primary" onClick={handleBoostClick}>
          <RocketLaunch />
        </IconButton>
      </Box>
    </Box>
  );

  if (!ready || loading) return <CircularProgress />;
  if (!authenticated) return <Typography>Redirecting to login...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!post) return <Typography>Post not found</Typography>;

  if (showQuoteTweet) {
    return (
      <QuoteTweet
        postToQuote={itemToQuote}
        onClose={handleQuoteClose}
        onSuccess={handleQuoteSuccess}
      />
    );
  }

  return (
    <Box sx={{ 
      height: '100vh', 
      width: '100vw',
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: 'background.default',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}>
      {!showFullScreenComment ? (
        <>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            p: 2,
            pt: 'calc(env(safe-area-inset-top) + 8px)',
            pb: 1,
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'background.default',
          }}>
            <IconButton onClick={handleBack} aria-label="back">
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Post</Typography>
          </Box>
          <Box sx={{ 
            flexGrow: 1, 
            overflow: 'auto', 
            p: 2,
            pb: 'calc(env(safe-area-inset-bottom) + 56px)',
          }}>
            {renderPostOrComment(post, true, -1)}
            
            {commentThread.length > 0 && (
              <Box sx={{ borderLeft: '2px solid #cba6f7', pl: 2, mt: 3 }}>
                {commentThread.map((comment, index) => (
                  <Box key={comment.uniqueCommentId}>
                    {renderPostOrComment(comment, false, index)}
                  </Box>
                ))}
              </Box>
            )}
            
            <Box>
              {(commentThread.length > 0 ? replies : topLevelComments).map((comment) => (
                <Comment 
                  key={comment.uniqueCommentId}
                  postId={post.id}
                  uniqueId={post.uniqueId}
                  postOwner={post.privyUserId || post.userId}
                  comment={comment}
                  allComments={sortedComments}
                  onClick={() => handleCommentClick(comment, -1)}
                  onReply={() => handleOpenFullScreenComment(comment)}
                  replyCount={getReplyCount(comment.uniqueCommentId)}
                  likes={likes[comment.uniqueCommentId] || { count: 0, users: [] }}
                  onLike={() => handleLike(comment.uniqueCommentId)}
                  currentUserId={user?.id}
                  isBoosted={comment.isBoosted}
                  boostedAmount={comment.isBoosted ? ethers.utils.formatEther(comment.amountPaid || '0') : '0'}
                  onQuote={() => handleQuoteButtonClick(comment)}
                  setShowBottomNav={setShowBottomNav}
                />
              ))}
            </Box>
          </Box>
        </>
      ) : (
        renderFullScreenComment()
      )}
      <Dialog open={showBoostDialog} onClose={handleBoostDialogClose}>
        <DialogTitle>Boost your comment</DialogTitle>
        <DialogContent>
          <Input
            autoFocus
            type="number"
            value={boostInputValue}
            onChange={(e) => setBoostInputValue(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            inputProps={{ min: 0, step: 0.01 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBoostDialogClose}>Cancel</Button>
          <Button onClick={handleBoostConfirm}>Set</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default React.memo(PostView);