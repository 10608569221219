import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Container, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, Modal } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { usePrivyAuth } from '../firebase/auth';
import AnimatedFeeFlowChart from './AnimatedFeeFlowChart';
import { useSwipeable } from 'react-swipeable';

const Login = () => {
  const { login, ready, authenticated } = usePrivyAuth();
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState(0);
  const sectionRefs = useRef([]);
  const containerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionRefs.current.findIndex((ref) => ref === entry.target);
            setCurrentSection(index);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const scrollToSection = (index) => {
    if (index >= 0 && index < sections.length) {
      sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleArrowClick = () => {
    scrollToSection(1); // Scroll to the second section (index 1)
  };

  const handleLogin = () => {
    if (isMobile) {
      login();
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const sections = [
    {
      content: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between',
            py: isMobile ? 6 : 8,
          }}
        >
          <br/>
          <br/>
          <br/>
          <Typography
            variant={isMobile ? "h4" : "h3"}
            sx={{
              fontFamily: "'Noto Sans JP', sans-serif",
              color: "#b4befe",
              mb: isMobile ? 4 : 6,
              textAlign: 'center',
            }}
          >
            welcome to inari
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                width: '60%',
                maxWidth: 250,
                mb: isMobile ? 4 : 6,
              }}
              alt="Kitsune Gate Logo"
              src={`${process.env.PUBLIC_URL}/maink.svg`}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={!ready || authenticated}
              sx={{
                bgcolor: '#f38ba8',
                '&:hover': { bgcolor: '#eba0ac' },
                mt: isMobile ? 2 : 4,
                mb: 4,
              }}
            >
              Enter
            </Button>
            <br/>
            <br/>
            <Box sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={handleArrowClick}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                learn more
              </Typography>
              <KeyboardArrowDownIcon 
                sx={{ 
                  fontSize: 40,
                  animation: 'bounce 2s infinite',
                  '@keyframes bounce': {
                    '0%, 20%, 50%, 80%, 100%': {
                      transform: 'translateY(0)',
                    },
                    '40%': {
                      transform: 'translateY(-20px)',
                    },
                    '60%': {
                      transform: 'translateY(-10px)',
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )
    },
    {
      title: "A Social Experiment",
      titleColor: "#f9e2af",
      content: "inari is a experimental social network with the goal of making it easier for creators to monitize and share their work."
    },
    {
      title: "Introducing Content Liquidity",
      titleColor: "#89b4fa",
      content: "Monetize every interaction with your posts. Whether it's a direct reply or users engaging in your comment section, earn revenue each time someone interacts with your content."
    },
    {
      title: "Tradable Content",
      titleColor: "#a6e3a1", 
      content: "Think you posted a banger? Let the market decide how much it's worth."
    },
    {
      title: "How It Works",
      titleColor: "#cba6f7",
      content: (
        <Box sx={{ width: '100%' }}>
          <Accordion sx={{ bgcolor: '#1e1e2e', color: '#f5e0dc' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#f5e0dc' }} />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Post Monetization</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Every time someone comments on your post, they have to pay you a small fee of 1 USD. If users are replying to each other in your comments, you get 50% of the fee while the user being replied to gets 50%. This works the same if you are making an original post or quote tweeting another post.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ bgcolor: '#1e1e2e', color: '#f5e0dc' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#f5e0dc' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Make Your Posts Tradable</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When making a post, you can make that post tradable. It's kind of like a new version of meme coins, but this time you will be trading the actual meme. You will be issued one fragment of the post, and users can buy and sell fragments on a bonding curve. Collect fees every time users buy or sell your posts.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ bgcolor: '#1e1e2e', color: '#f5e0dc' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#f5e0dc' }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Boost Comments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Want to get the poster to notice you? Or maybe you want to capitalize on the popularity of a post and have your comment shown at the top of the thread. You can pay the poster a boost fee to be shown as the top comment. The minimum boost fee is 10 USD; the more you pay, the higher you are displayed.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      )
      },
      ];
      
  useEffect(() => {
    const handleWheel = (e) => {
      const delta = e.deltaY;
      if (delta > 0 && currentSection < sections.length - 1) {
        scrollToSection(currentSection + 1);
      } else if (delta < 0 && currentSection > 0) {
        scrollToSection(currentSection - 1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel);

      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }
  }, [currentSection, sections.length]);

  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      if (currentSection < sections.length - 1) {
        scrollToSection(currentSection + 1);
      }
    },
    onSwipedDown: (eventData) => {
      if (currentSection > 0) {
        scrollToSection(currentSection - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Box
      ref={containerRef}
      {...swipeHandlers}
      sx={{
        bgcolor: '#181825',
        height: '100vh',
        color: '#f5e0dc',
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Container maxWidth="sm">
        {sections.map((section, index) => (
          <Box
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              px: isMobile ? 2 : 4,
              scrollSnapAlign: 'start',
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              gutterBottom
              sx={{
                fontFamily: "'Noto Sans JP', sans-serif",
                color: section.titleColor,
                mb: isMobile ? 2 : 3,
              }}
            >
              {section.title}
            </Typography>
            {typeof section.content === 'string' ? (
              <Typography 
                variant="body1" 
                sx={{ 
                  maxWidth: '100%',
                  fontSize: isMobile ? '1rem' : '1.1rem',
                  lineHeight: 1.6,
                }}
              >
                {section.content}
              </Typography>
            ) : (
              section.content
            )}
          </Box>
        ))}
      </Container>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#1e1e2e',
          border: '2px solid #cba6f7',
          boxShadow: 24,
          p: 4,
          color: '#f5e0dc',
          textAlign: 'center',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Mobile-First Beta
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 3 }}>
         While you can access inari using browser developer tools, we highly recommend installing it on your phone's home screen for the best experience and optimal functionality. A web version will be available shortly.
          </Typography>
          <Button onClick={handleCloseModal} sx={{ bgcolor: '#f38ba8', color: '#1e1e2e', '&:hover': { bgcolor: '#eba0ac' } }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Login;