import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Typography, Box, CircularProgress, Snackbar, IconButton, Dialog, DialogContent, Avatar } from '@mui/material';
import { collection, query, orderBy, limit, startAfter, getDocs, where, getCountFromServer, doc, onSnapshot, setDoc, getDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import { usePrivy } from '@privy-io/react-auth';
import { useInView } from 'react-intersection-observer';
import QuoteTweet from './QuoteTweet';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IosShare } from '@mui/icons-material';

const POSTS_PER_PAGE = 10;

const formatDate = (date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  return `${seconds}s`;
};

const FrostedGlassBox = styled(Box, {
  shouldForwardProp: (prop) => !['intensity', 'isDarkMode', 'isTransparent', 'outlineColor', 'isContainer'].includes(prop)
})(({ theme, intensity, isDarkMode, isTransparent, outlineColor, isContainer }) => {
  const getBackgroundColor = () => {
    if (isDarkMode) {
      return intensity === 'low' ? 'rgba(49, 50, 68, 0.8)' :
             intensity === 'medium' ? 'rgba(30, 30, 46, 0.9)' :
             'rgba(30, 30, 30, 1)';
    } else {
      return intensity === 'low' ? 'rgba(255, 255, 255, 0.8)' :
             intensity === 'medium' ? 'rgba(255, 255, 255, 0.9)' :
             'rgba(255, 255, 255, 1)';
    }
  };

  const getBorderColor = () => outlineColor || (isDarkMode ? 'rgba(180, 190, 254, 0.2)' : 'rgba(0, 0, 0, 0.2)');

  return {
    background: isTransparent ? 'transparent' : getBackgroundColor(),
    backdropFilter: isTransparent ? 'none' : 'blur(10px)',
    borderRadius: isContainer ? '0' : '12px',
    border: isContainer ? 'none' : `1px solid ${getBorderColor()}`,
    boxShadow: isContainer || isTransparent ? 'none' : `0 4px 30px ${isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
    transition: 'all 0.3s ease-in-out',
    padding: isContainer ? '0' : '12px',
    marginBottom: isContainer ? '0' : '16px',
    '&:hover': {
      boxShadow: isContainer || isTransparent ? 'none' : `0 6px 40px ${isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(180, 190, 254, 0.2)'}`,
    },
  };
});

const Post = React.memo(({ post, commentCount, quoteCount, handleLike, handleQuoteButtonClick, currentUserId, likes }) => {
  const navigate = useNavigate();
  const likeData = likes[post.id] || { count: 0, users: [] };
  const isLiked = likeData.users.includes(currentUserId);

  const handleCommentClick = (e) => {
    e.preventDefault();
    navigate(`/post/${post.id}`);
  };

  return (
    <FrostedGlassBox isDarkMode intensity="medium" sx={{ mb: 2, p: 2, borderRadius: 2 }}>
      <Link to={`/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Avatar src={post.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 48, height: 48, mr: 2 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1, color: '#f9e2af' }}>
                @{post.userName || 'Anonymous'}
              </Typography>
              <Typography variant="caption" sx={{ color: '#7f849c' }}>
                · {post.createdAt ? formatDate(new Date(post.createdAt.seconds * 1000)) : 'Date unavailable'}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: '#cdd6f4', whiteSpace: 'pre-wrap' }}>
              {post.content || post.text}
            </Typography>
            {post.imageUrl && (
              <Box 
                component="img"
                src={post.imageUrl}
                sx={{ width: '100%', borderRadius: 2, mb: 2 }}
                alt="Post content"
                loading="lazy"
              />
            )}
            {post.isQuoteTweet && post.originalPostText && (
              <FrostedGlassBox isDarkMode intensity="low" sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={post.originalUserPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
                  <Typography variant="subtitle2" sx={{ color: '#cdd6f4' }}>
                    @{post.originalUserName || post.originalPostAuthor || 'Anonymous'}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: '#cdd6f4' }}>
                  {post.originalPostText}
                </Typography>
              </FrostedGlassBox>
            )}
          </Box>
        </Box>
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <IconButton size="small" onClick={handleCommentClick}>
          <ChatBubbleOutlineIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {commentCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={() => handleQuoteButtonClick(post)}>
          <RepeatIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {quoteCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={(e) => { e.preventDefault(); handleLike(post.id); }}>
          {isLiked ? (
            <FavoriteIcon fontSize="small" sx={{ color: '#f38ba8' }} />
          ) : (
            <FavoriteBorderIcon fontSize="small" sx={{ color: '#7f849c' }} />
          )}
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {likeData.count}
          </Typography>
        </IconButton>
        <IconButton size="small">
          <IosShare fontSize="small" sx={{ color: '#7f849c' }} />
        </IconButton>
      </Box>
    </FrostedGlassBox>
  );
});

const FollowingFeed = () => {
  const { ready, authenticated, login, user } = usePrivy();
  const [error, setError] = useState(null);
  const [showQuoteTweet, setShowQuoteTweet] = useState(false);
  const [postToQuote, setPostToQuote] = useState(null);
  const { ref: loadMoreRef, inView } = useInView();
  const queryClient = useQueryClient();
  const [commentCounts, setCommentCounts] = useState({});
  const [quoteCounts, setQuoteCounts] = useState({});
  const [likes, setLikes] = useState({});
  const [following, setFollowing] = useState([]);

  useEffect(() => {
    if (!ready) {
      console.log('Privy is initializing...');
    } else if (!authenticated) {
      console.log('User is not authenticated, redirecting to login...');
      login();
    } else {
      // Fetch the user's following list
      const fetchFollowing = async () => {
        const userDoc = await getDoc(doc(firestore, 'users', user.id));
        if (userDoc.exists()) {
          const followingList = userDoc.data().following || [];
          setFollowing(followingList);
          console.log('Following list:', followingList);
        } else {
          console.log('User document does not exist');
          setFollowing([]);
        }
      };
      
      fetchFollowing();
    }
  }, [ready, authenticated, login, user]);

  const fetchCommentCounts = async (posts) => {
    const counts = {};
    const countPromises = posts.map(async (post) => {
      const commentsRef = collection(firestore, `posts/${post.id}/comments`);
      const snapshot = await getCountFromServer(commentsRef);
      counts[post.id] = snapshot.data().count;
    });
    await Promise.all(countPromises);
    return counts;
  };

  const fetchQuoteCounts = async (posts) => {
    const counts = {};
    const countPromises = posts.map(async (post) => {
      const quotesRef = query(collection(firestore, 'posts'), where('originalPostId', '==', post.id));
      const snapshot = await getCountFromServer(quotesRef);
      counts[post.id] = snapshot.data().count;
    });
    await Promise.all(countPromises);
    return counts;
  };

  const fetchFollowingPosts = useCallback(async ({ pageParam = null }) => {
    console.log('Fetching following posts...');
    console.log('Current following list:', following);
  
    if (following.length === 0) {
      console.log('Following list is empty');
      return { posts: [], lastVisible: null };
    }
  
    let q = query(
      collection(firestore, 'posts'),
      where('userId', 'in', following),
      orderBy('createdAt', 'desc'),
      limit(POSTS_PER_PAGE)
    );
  
    if (pageParam) {
      q = query(q, startAfter(pageParam));
    }
  
    const snapshot = await getDocs(q);
    console.log(`Fetched ${snapshot.docs.length} posts`);
  
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];
  
    const postsData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  
    console.log('Fetched posts:', postsData);
  
    const [commentCountsData, quoteCountsData] = await Promise.all([
      fetchCommentCounts(postsData),
      fetchQuoteCounts(postsData)
    ]);
  
    setCommentCounts(prevCounts => ({ ...prevCounts, ...commentCountsData }));
    setQuoteCounts(prevCounts => ({ ...prevCounts, ...quoteCountsData }));
  
    return { posts: postsData, lastVisible };
  }, [following, fetchCommentCounts, fetchQuoteCounts]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error: queryError,
  } = useInfiniteQuery('followingPosts', fetchFollowingPosts, {
    getNextPageParam: (lastPage) => lastPage.lastVisible || undefined,
    enabled: ready && authenticated && following.length > 0,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (authenticated && data) {
      const unsubscribes = data.pages.flatMap(page => 
        page.posts.map(post => {
          return onSnapshot(
            collection(firestore, `posts/${post.id}/likes`),
            (snapshot) => {
              const likesData = {};
              snapshot.docs.forEach(doc => {
                likesData[doc.id] = doc.data();
              });
              setLikes(prevLikes => ({...prevLikes, [post.id]: likesData[post.id] || { count: 0, users: [] }}));
            },
            (err) => {
              console.error('Error fetching likes:', err);
            }
          );
        })
      );

      return () => unsubscribes.forEach(unsub => unsub());
    }
  }, [authenticated, data]);

  const handleQuoteButtonClick = useCallback((post) => {
    setPostToQuote(post);
    setShowQuoteTweet(true);
  }, []);

  const handleQuoteClose = useCallback(() => {
    setShowQuoteTweet(false);
    setPostToQuote(null);
  }, []);

  const handleQuoteSuccess = useCallback(() => {
    handleQuoteClose();
    queryClient.invalidateQueries('followingPosts');
  }, [handleQuoteClose, queryClient]);

  const handleLike = useCallback(async (postId) => {
    if (!authenticated || !user) return;
    
    const likeRef = doc(firestore, `posts/${postId}/likes`, postId);
    const currentLikes = likes[postId] || { count: 0, users: [] };
    const isLiked = currentLikes.users.includes(user.id);
  
    try {
      if (isLiked) {
        await setDoc(likeRef, {
          count: currentLikes.count - 1,
          users: arrayRemove(user.id)
        }, { merge: true });
      } else {
        await setDoc(likeRef, {
          count: currentLikes.count + 1,
          users: arrayUnion(user.id)
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating like:', error);
      setError('Failed to update like');
    }
  }, [authenticated, user, likes]);

  const memoizedPosts = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap(page => page.posts);
  }, [data]);

  if (!ready) return <CircularProgress />;
  if (!authenticated) return <Typography>Redirecting to login...</Typography>;

  if (status === 'loading') return <CircularProgress />;
  if (status === 'error') return <Typography>Error fetching posts: {queryError.message}</Typography>;

  if (showQuoteTweet) {
    return (
      <QuoteTweet
        postToQuote={postToQuote}
        onClose={handleQuoteClose}
        onSuccess={handleQuoteSuccess}
      />
    );
  }

  return (
    <Box sx={{ maxWidth: '600px', margin: '0 auto', p: 2, minHeight: '100vh' }}>
      {memoizedPosts.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', mt: 4, color: '#cdd6f4' }}>
          You're not following anyone yet or the users you follow haven't posted anything.
        </Typography>
      ) : (
        memoizedPosts.map((post) => (
          <Post 
            key={post.id}
            post={post}
            handleQuoteButtonClick={handleQuoteButtonClick}
            commentCount={commentCounts[post.id] || 0}
            quoteCount={quoteCounts[post.id] || 0}
            handleLike={handleLike}
            currentUserId={user?.id}
            likes={likes}
          />
        ))
      )}
      {isFetchingNextPage && <CircularProgress />}
      <div ref={loadMoreRef} style={{ height: 20 }} />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default React.memo(FollowingFeed);