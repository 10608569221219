import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Container, CircularProgress, Snackbar, Box, Typography, Fab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import { usePrivyAuth } from './firebase/auth';
import Feed from './components/Feed';
import FollowingFeed from './components/FollowingFeed';
import NewPost from './components/NewPost';
import Login from './components/Login';
import Profile from './components/Profile';
import BottomNav from './components/BottomNav';
import CustomAppBar from './components/CustomAppBar';
import PostView from './components/PostView';
import SearchPage from './components/SearchPage';
import Notifications from './components/Notifications';
import PushNotificationSubscriber from './components/PushNotificationSubscriber';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase/firebase';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#181825',
      paper: '#181825',
    },
    primary: {
      main: '#b4befe',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#181825',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#b4befe',
        },
      },
    },
  },
});

const CenteredLoading = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress />
  </Box>
);

const SwipeableWrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1),
    onSwipedLeft: () => navigate(1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return <div {...handlers}>{children}</div>;
};

const Layout = ({ children, showAddButton = true, user, showBottomNav, showAppBar = true }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
    }}>
      {showAppBar && <CustomAppBar user={user} />}
      <Box sx={{ 
        flex: 1, 
        overflow: 'auto', 
        pt: showAppBar ? 'calc(env(safe-area-inset-top) + 56px)' : 0, 
        pb: showBottomNav ? 'calc(env(safe-area-inset-bottom) + 56px)' : 0,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ 
          maxWidth: '600px', 
          width: '100%', 
          margin: '0 auto', 
          p: 2, 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          {children}
        </Box>
      </Box>
      {showBottomNav && <BottomNav />}
      {showAddButton && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'fixed', bottom: 'calc(env(safe-area-inset-bottom) + 70px)', right: 16 }}
          onClick={() => navigate('/new-post')}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  );
};

const Home = () => {
  const { authenticated, ready, user, syncUserWithFirebase } = usePrivyAuth();
  const [error, setError] = useState(null);
  
  useEffect(() => {
    console.log('User object:', user);
    if (authenticated && user) {
      syncUserWithFirebase()
        .then(() => console.log('User synced with Firebase'))
        .catch(error => {
          console.error('Error syncing user with Firebase:', error);
          setError('Failed to sync user with Firebase. Please try again.');
        });
    }
  }, [authenticated, user, syncUserWithFirebase]);

  if (!ready) return <CenteredLoading />;
  if (!authenticated) return <Navigate to="/login" />;

  return (
    <>
      <Feed user={user} />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

const PrivateRoute = ({ children, showAddButton = true, showAppBar = true }) => {
  const { authenticated, ready, user } = usePrivyAuth();
  const [showBottomNav, setShowBottomNav] = useState(true);
  
  if (!ready) return <CenteredLoading />;
  if (!authenticated) return <Navigate to="/login" />;
  
  return (
    <SwipeableWrapper>
      <Layout showAddButton={showAddButton} user={user} showBottomNav={showBottomNav} showAppBar={showAppBar}>
        {React.cloneElement(children, { setShowBottomNav })}
      </Layout>
    </SwipeableWrapper>
  );
};

const App = ({ initialState }) => {
  const { ready, user } = usePrivyAuth();
  const [appState, setAppState] = useState(initialState || {});

  useEffect(() => {
    if (ready && user) {
      setAppState(prevState => ({ ...prevState, user }));
    }
  }, [ready, user]);

  useEffect(() => {
    // Force layout recalculation after component mounts
    window.dispatchEvent(new Event('resize'));
  }, []);

  if (!ready) {
    return <CenteredLoading />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <PushNotificationSubscriber />
      <SwipeableWrapper>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/following" element={<PrivateRoute><FollowingFeed /></PrivateRoute>} />
          <Route path="/new-post" element={<PrivateRoute showAddButton={false} showAppBar={false}><NewPost user={user} /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile userId={user?.id} /></PrivateRoute>} />
          <Route path="/:twitterHandle" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/post/:postId" element={<PrivateRoute showAddButton={false}><PostView /></PrivateRoute>} />
          <Route path="/post/:postId/comment/:commentId" element={<PrivateRoute showAddButton={false} showAppBar={false}><PostView /></PrivateRoute>} />
          <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
          <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
        </Routes>
      </SwipeableWrapper>
    </ThemeProvider>
  );
};

export default App;