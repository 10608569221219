import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Typography, Avatar, Box, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { collection, query, orderBy, limit, startAfter, getDocs, where, getCountFromServer, doc, updateDoc, arrayUnion, arrayRemove, onSnapshot, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { usePrivy } from '@privy-io/react-auth';
import { useInView } from 'react-intersection-observer';
import QuoteTweet from './QuoteTweet';
import { IosShare } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import FrostedGlass from './FrostedGlass';

const POSTS_PER_PAGE = 10;

const formatDate = (date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  return `${seconds}s`;
};

const Post = React.memo(({ post, commentCount, quoteCount, handleLike, handleQuoteButtonClick, currentUserId, likes }) => {
  const navigate = useNavigate();
  const likeData = likes[post.id] || { count: 0, users: [] };
  const isLiked = likeData.users.includes(currentUserId);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCommentClick = (e) => {
    e.preventDefault();
    navigate(`/post/${post.id}`);
  };

  const handleShare = async () => {
    const postUrl = `${window.location.origin}/post/${post.id}`;
    
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Post by @${post.userName}`,
          text: post.content.substring(0, 50) + '...',
          url: postUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback to copying the link
      navigator.clipboard.writeText(postUrl).then(() => {
        setSnackbarMessage('Link copied to clipboard!');
        setShowSnackbar(true);
      }, (err) => {
        console.error('Could not copy text: ', err);
        setSnackbarMessage('Failed to copy link');
        setShowSnackbar(true);
      });
    }
  };

  return (
    <FrostedGlass 
      darkMode 
      intensity="medium" 
      sx={{ 
        mb: 3, 
        cursor: 'pointer',
        borderRadius: '8px',
        position: 'relative',
        p: 2,
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Link to={`/${post.userName}`} onClick={(e) => e.stopPropagation()}>
          <Avatar src={post.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 48, height: 48, mr: 2 }} />
        </Link>
        <Box sx={{ flexGrow: 1 }}>
          <Link to={`/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1, color: '#f9e2af' }}>
                @{post.userName || 'Anonymous'}
              </Typography>
              <Typography variant="caption" sx={{ color: '#7f849c' }}>
                · {post.createdAt ? formatDate(new Date(post.createdAt.seconds * 1000)) : 'Date unavailable'}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: '#cdd6f4', whiteSpace: 'pre-wrap' }}>
              {post.content || post.text}
            </Typography>
            {post.imageUrl && (
              <Box 
                component="img"
                src={post.imageUrl}
                sx={{ width: '100%', borderRadius: 2, mb: 2 }}
                alt="Post content"
                loading="lazy"
              />
            )}
            {post.isQuoteTweet && (post.originalPostText || post.originalPostImageUrl) && (
              <FrostedGlass darkMode intensity="low" sx={{ p: 2, borderRadius: 2, mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={post.originalUserPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
                  <Typography variant="subtitle2" sx={{ color: '#cdd6f4' }}>
                    @{post.originalUserName || post.originalPostAuthor || 'Anonymous'}
                  </Typography>
                </Box>
                {post.originalPostText && (
                  <Typography variant="body2" sx={{ color: '#cdd6f4', mb: 1 }}>
                    {post.originalPostText}
                  </Typography>
                )}
                {post.originalPostImageUrl && (
                  <Box 
                    component="img"
                    src={post.originalPostImageUrl}
                    sx={{ width: '100%', borderRadius: 2, mt: 1 }}
                    alt="Original post content"
                    loading="lazy"
                  />
                )}
              </FrostedGlass>
            )}
          </Link>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <IconButton size="small" onClick={handleCommentClick}>
          <ChatBubbleOutlineIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {commentCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={() => handleQuoteButtonClick(post)}>
          <RepeatIcon fontSize="small" sx={{ color: '#7f849c' }} />
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {quoteCount}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={(e) => { e.preventDefault(); handleLike(post.id); }}>
          {isLiked ? (
            <FavoriteIcon fontSize="small" sx={{ color: '#f38ba8' }} />
          ) : (
            <FavoriteBorderIcon fontSize="small" sx={{ color: '#7f849c' }} />
          )}
          <Typography variant="caption" sx={{ ml: 1, color: '#7f849c' }}>
            {likeData.count}
          </Typography>
        </IconButton>
        <IconButton size="small" onClick={handleShare}>
          <IosShare fontSize="small" sx={{ color: '#7f849c' }} />
        </IconButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </FrostedGlass>
  );
});

const Feed = () => {
  const { ready, authenticated, login, user } = usePrivy();
  const [error, setError] = useState(null);
  const [showQuoteTweet, setShowQuoteTweet] = useState(false);
  const [postToQuote, setPostToQuote] = useState(null);
  const { ref: loadMoreRef, inView } = useInView();
  const queryClient = useQueryClient();
  const [commentCounts, setCommentCounts] = useState({});
  const [quoteCounts, setQuoteCounts] = useState({});
  const [likes, setLikes] = useState({});

  useEffect(() => {
    if (!ready) {
      console.log('Privy is initializing...');
    } else if (!authenticated) {
      console.log('User is not authenticated, redirecting to login...');
      login();
    }
  }, [ready, authenticated, login]);

  const fetchCommentCounts = async (posts) => {
    const counts = {};
    const countPromises = posts.map(async (post) => {
      const commentsRef = collection(firestore, `posts/${post.id}/comments`);
      const snapshot = await getCountFromServer(commentsRef);
      counts[post.id] = snapshot.data().count;
    });
    await Promise.all(countPromises);
    return counts;
  };

  const fetchQuoteCounts = async (posts) => {
    const counts = {};
    const countPromises = posts.map(async (post) => {
      const quotesRef = query(collection(firestore, 'posts'), where('originalPostId', '==', post.id));
      const snapshot = await getCountFromServer(quotesRef);
      counts[post.id] = snapshot.data().count;
    });
    await Promise.all(countPromises);
    return counts;
  };

  const fetchPosts = useCallback(async ({ pageParam = null }) => {
    let q = query(
      collection(firestore, 'posts'),
      orderBy('createdAt', 'desc'),
      limit(POSTS_PER_PAGE)
    );

    if (pageParam) {
      q = query(q, startAfter(pageParam));
    }

    const snapshot = await getDocs(q);
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];

    const postsData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const [commentCountsData, quoteCountsData] = await Promise.all([
      fetchCommentCounts(postsData),
      fetchQuoteCounts(postsData)
    ]);

    setCommentCounts(prevCounts => ({ ...prevCounts, ...commentCountsData }));
    setQuoteCounts(prevCounts => ({ ...prevCounts, ...quoteCountsData }));

    return { posts: postsData, lastVisible };
  }, []);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error: queryError,
  } = useInfiniteQuery('posts', fetchPosts, {
    getNextPageParam: (lastPage) => lastPage.lastVisible || undefined,
    enabled: ready && authenticated,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (authenticated && data) {
      const unsubscribes = data.pages.flatMap(page => 
        page.posts.map(post => {
          return onSnapshot(
            collection(firestore, `posts/${post.id}/likes`),
            (snapshot) => {
              const likesData = {};
              snapshot.docs.forEach(doc => {
                likesData[doc.id] = doc.data();
              });
              setLikes(prevLikes => ({...prevLikes, [post.id]: likesData[post.id] || { count: 0, users: [] }}));
            },
            (err) => {
              console.error('Error fetching likes:', err);
            }
          );
        })
      );

      return () => unsubscribes.forEach(unsub => unsub());
    }
  }, [authenticated, data]);

  const handleQuoteButtonClick = useCallback((post) => {
    setPostToQuote(post);
    setShowQuoteTweet(true);
  }, []);

  const handleQuoteClose = useCallback(() => {
    setShowQuoteTweet(false);
    setPostToQuote(null);
  }, []);

  const handleQuoteSuccess = useCallback(() => {
    handleQuoteClose();
    queryClient.invalidateQueries('posts');
  }, [handleQuoteClose, queryClient]);

  const handleLike = useCallback(async (postId) => {
    if (!authenticated || !user) return;
    
    const likeRef = doc(firestore, `posts/${postId}/likes`, postId);
    const currentLikes = likes[postId] || { count: 0, users: [] };
    const isLiked = currentLikes.users.includes(user.id);
  
    try {
      if (isLiked) {
        await setDoc(likeRef, {
          count: currentLikes.count - 1,
          users: arrayRemove(user.id)
        }, { merge: true });
      } else {
        await setDoc(likeRef, {
          count: currentLikes.count + 1,
          users: arrayUnion(user.id)
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating like:', error);
      setError('Failed to update like');
    }
  }, [authenticated, user, likes]);

  const memoizedPosts = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap(page => page.posts);
  }, [data]);

  if (!ready) return <CircularProgress />;
  if (!authenticated) return <Typography>Redirecting to login...</Typography>;

  if (status === 'loading') return <CircularProgress />;
  if (status === 'error') return <Typography>Error fetching posts: {queryError.message}</Typography>;

  if (showQuoteTweet) {
    return (
      <QuoteTweet
        postToQuote={postToQuote}
        onClose={handleQuoteClose}
        onSuccess={handleQuoteSuccess}
      />
    );
  }

  return (
    <Box sx={{ maxWidth: '600px', margin: '0 auto', p: 2, minHeight: '100vh' }}>
      {memoizedPosts.map((post) => (
        <Post 
          key={post.id}
          post={post}
          handleQuoteButtonClick={handleQuoteButtonClick}
          commentCount={commentCounts[post.id] || 0}
          quoteCount={quoteCounts[post.id] || 0}
          handleLike={handleLike}
          currentUserId={user?.id}
          likes={likes}
        />
      ))}
      {isFetchingNextPage && <CircularProgress />}
      <div ref={loadMoreRef} style={{ height: 20 }} />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default React.memo(Feed);