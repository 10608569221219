export const FANTOM_TESTNET = {
  id: 1328,
  name: 'Sei',
  network: 'fantom-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Sei',
    symbol: 'SEI',
  },
  rpcUrls: {
    default: {
      http: ['https://evm-rpc-testnet.sei-apis.com'],
    },
  },
  blockExplorers: {
    default: { name: 'FTMScan', url: 'https://explorer-testnet.soneium.org' },
  },
};

export const CURRENT_NETWORK = FANTOM_TESTNET;

export const CHAIN_ID = CURRENT_NETWORK.id;
export const RPC_URL = CURRENT_NETWORK.rpcUrls.default.http[0];
export const BLOCK_EXPLORER_URL = CURRENT_NETWORK.blockExplorers.default.url;