import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, storage, serverTimestamp } from '../firebase/firebase';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  TextField, 
  Button, 
  Snackbar, 
  Box, 
  IconButton, 
  CircularProgress, 
  AppBar, 
  Toolbar, 
  Typography,
  Avatar
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CloseIcon from '@mui/icons-material/Close';
import data from '@emoji-mart/data/sets/14/twitter.json'
import Picker from '@emoji-mart/react'
import { useEthereumManager } from '../utils/eth';
import { useWallets } from '@privy-io/react-auth';
import { encodeFunctionData, decodeEventLog } from 'viem';
import { ethers } from 'ethers';
import BANGER_ABI from '../contract/BangerABI.json';
import FrostedGlass from './FrostedGlass';

const BANGER_ADDRESS = process.env.REACT_APP_BANGER_CONTRACT_ADDRESS;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '0x530';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_DIMENSION = 4096; // Twitter's max dimension

const processImage = async (file) => {
  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 5MB limit. Please choose a smaller file.');
  }

  if (!file.type.startsWith('image/')) {
    throw new Error('Please upload an image file.');
  }

  if (file.type === 'image/gif') {
    return file;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      let { width, height } = img;
      
      if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
        if (width > height) {
          height *= MAX_DIMENSION / width;
          width = MAX_DIMENSION;
        } else {
          width *= MAX_DIMENSION / height;
          height = MAX_DIMENSION;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(resolve, file.type, 0.95);
    };
    img.src = URL.createObjectURL(file);
  });
};

const NewPost = ({ user }) => {
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const { authenticated, login, getDebugPriceAndFee } = useEthereumManager();
  const { wallets } = useWallets();
  const navigate = useNavigate();
  const textFieldRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }

    const handleResize = () => {
      const newKeyboardHeight = window.innerHeight - window.visualViewport.height;
      setKeyboardHeight(newKeyboardHeight);
    };

    window.visualViewport.addEventListener('resize', handleResize);
    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('Text state updated:', text);
  }, [text]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedImage = await processImage(file);
        setImage(processedImage);
        setPreview(URL.createObjectURL(processedImage));
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleEmojiSelect = (emoji) => {
    console.log('Emoji selected:', emoji);
    console.log('Current text:', text);
    setText(prevText => {
      const newText = prevText + emoji.native;
      console.log('New text:', newText);
      return newText;
    });
    setShowEmojiPicker(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!text.trim() && !image) {
      setError('Please enter some text or upload an image.');
      return;
    }
    setLoading(true);

    try {
      if (!authenticated) {
        await login();
      }

      const { txHash, postId, postOwner, uniqueId } = await createPostOnBlockchain(text);
      console.log('Post created on blockchain, transaction hash:', txHash);
      console.log('Post ID:', postId);
      console.log('Post Owner:', postOwner);
      console.log('Unique ID:', uniqueId);

      let imageUrl = null;
      if (image) {
        const imageRef = ref(storage, `posts/${user.id}/${Date.now()}_${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const newPost = {
        text: text.trim(),
        imageUrl,
        createdAt: serverTimestamp(),
        userId: user.id,
        userName: user.twitter?.username || 'Anonymous',
        userPhoto: user.twitter?.profilePictureUrl?.replace('_normal', '') || 'default-photo-url',
        postId: postId,
        postOwner: postOwner,
        uniqueId: uniqueId
      };

      const postDocRef = await addDoc(collection(firestore, 'posts'), newPost);
      console.log('Post added to Firestore with ID:', postDocRef.id);

      setText('');
      setImage(null);
      setPreview(null);
      setError('Post created successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error adding post: ', error);
      setError(`Failed to add post: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const createPostOnBlockchain = async (content) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const feeInWei = await contract.calculateFeeInWei(1); // 1 USD fee for creating a post
  
      console.log('Fee in Wei:', ethers.utils.formatEther(feeInWei), 'SEI');
  
      const debugInfo = await getDebugPriceAndFee();
      console.log('Debug Info:', debugInfo);
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: 'createPost',
        args: [content]
      });
  
      // EIP-1559 gas parameters
      const maxPriorityFeePerGas = ethers.utils.parseUnits('100', 'gwei'); // Set priority fee to 100 gwei
      const maxFeePerGas = maxPriorityFeePerGas.mul(2); // Set max fee to double the priority fee
  
      const gasLimit = await contract.estimateGas.createPost(content, { value: feeInWei });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        chainId: CHAIN_ID,
        from: wallet.address,
        maxPriorityFeePerGas: ethers.utils.hexValue(maxPriorityFeePerGas),
        maxFeePerGas: ethers.utils.hexValue(maxFeePerGas),
        gasLimit: ethers.utils.hexValue(gasLimit.mul(120).div(100)), // Add 20% buffer to estimated gas limit
        type: 2 // Explicitly set transaction type to EIP-1559
      };
  
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'SEI');
  
      if (ethers.BigNumber.from(balance).lt(feeInWei.add(maxFeePerGas.mul(gasLimit)))) {
        throw new Error("Insufficient balance to pay the fee and gas");
      }
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log(`Transaction sent. Hash: ${txHash}`);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log(`Transaction mined. Block number: ${receipt.blockNumber}`);
  
      const postCreatedEvent = receipt.logs.find(log => 
        log.address.toLowerCase() === BANGER_ADDRESS.toLowerCase()
      );
  
      if (!postCreatedEvent) {
        throw new Error("PostCreated event not found in transaction logs");
      }
  
      const decodedEvent = decodeEventLog({
        abi: BANGER_ABI,
        data: postCreatedEvent.data,
        topics: postCreatedEvent.topics,
      });
  
      const uniqueId = decodedEvent.args.uniqueId;
      const postId = decodedEvent.args.postId.toString();
  
      return { txHash, postId, postOwner: wallet.address, uniqueId };
    } catch (error) {
      console.error("Error creating post on blockchain:", error);
      throw error;
    }
  };

  const waitForTransaction = (provider, txHash) => {
    return new Promise((resolve, reject) => {
      const checkReceipt = async () => {
        try {
          const receipt = await provider.request({
            method: 'eth_getTransactionReceipt',
            params: [txHash],
          });
          if (receipt) {
            resolve(receipt);
          } else {
            setTimeout(checkReceipt, 1000);
          }
        } catch (error) {
          reject(error);
        }
      };
      checkReceipt();
    });
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: 'background.default',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}>
      <AppBar 
        position="static" 
        elevation={0}
        sx={{
          pt: 'env(safe-area-inset-top)',
          pb: 1,
          backgroundColor: 'background.default',
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate('/')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            New Post
          </Typography>
          <Button 
            color="inherit" 
            onClick={handleSubmit}
            disabled={loading || (!text.trim() && !image)}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Post'}
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        overflow: 'auto',
        paddingBottom: 'calc(60px + env(safe-area-inset-bottom))',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <Avatar src={user.twitter?.profilePictureUrl || 'https://via.placeholder.com/150'} sx={{ width: 40, height: 40, mr: 2 }} />
          <TextField
            inputRef={textFieldRef}
            placeholder="What's happening?"
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={4}
            variant="standard"
            InputProps={{ 
              disableUnderline: true,
              style: { color: '#cdd6f4' }
            }}
          />
        </Box>
        {preview && (
          <Box mb={2}>
            <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px' }} />
          </Box>
        )}
      </Box>
      <Box sx={{ 
        position: 'fixed', 
        bottom: `calc(${keyboardHeight}px + env(safe-area-inset-bottom))`, 
        left: 0, 
        right: 0, 
        borderTop: 1, 
        borderColor: 'divider', 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        zIndex: 1000,
        transition: 'bottom 0.3s'
      }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <IconButton color="primary" onClick={handleImageClick}>
          <PhotoCamera />
        </IconButton>
      </Box>
      {showEmojiPicker && (
        <Box sx={{ 
          position: 'absolute', 
          bottom: `calc(${keyboardHeight}px + 60px + env(safe-area-inset-bottom))`, 
          left: 0, 
          right: 0, 
          zIndex: 1001
        }}>
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            theme="dark"
            set="twitter"
          />
        </Box>
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default NewPost;